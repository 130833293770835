import axios from 'axios'
import constants from './../../../constants'
import store from './../../../store'

const baseURL = `${constants.API_URL}/api`

let axiosApi = axios.create({
  baseURL
}, 5000)
axiosApi.interceptors.request.use(
  (config) => {
    let token = store.getters.ApiToken
    //config.headers['Cache-Control'] = 'no-cache'
    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

export default axiosApi

